import React, { useState, useEffect, useContext, lazy, Suspense } from 'react';
import { Auth } from 'aws-amplify';

import { AuthContext } from './appConfigs/context/authContext/authContext';
import { LoginModalContext } from './appConfigs/context/loginModalContext/loginModalContext';
import useCurrentDimensions from './hooks/getWindowDimensions';
import ErrorBoundary from './components/common/errorBoundary/ErrorBoundary';

const Modal = lazy(() => import('./components/common/modal/modal'));
const Main = lazy(() => import('./components/main/Main'));
const Login = lazy(() => import('./components/login/Login'));

const renderLoader = () => <p>Loading</p>;

function App() {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const { authDispatch } = useContext(AuthContext);
  const { loginModalState, loginModalDispatch } = useContext(LoginModalContext);

  let windowDimensions = useCurrentDimensions();

  useEffect(() => {
    const onLoad = async () => {
      try {
        await Auth.currentSession();
        authDispatch({ type: 'RESUME_SESSION' });
      } catch (e) {
        if (e !== 'No current user') {
          console.log(e);
        }
      }
      setIsAuthenticating(false);
    };

    onLoad();
  }, [authDispatch]);

  return (
    <ErrorBoundary>
      <Suspense fallback={renderLoader}>
        <Modal
          isOpen={loginModalState.isOpen}
          handleClose={() =>
            loginModalDispatch({ type: 'SET_LOGIN_MODAL_CLOSED' })
          }
        >
          <Login />
        </Modal>

        <Main
          windowHeight={windowDimensions.windowHeight}
          windowWidth={windowDimensions.windowWidth}
          appProps={{ isAuthenticated, userHasAuthenticated, isAuthenticating }}
        />
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
