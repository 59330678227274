import React from 'react';

export const LoginModalContext = React.createContext();

export const initialLoginModalState = { isOpen: false };

export const loginModalReducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOGIN_MODAL_OPEN':
      return {
        ...state,
        isOpen: true,
      };

    case 'SET_LOGIN_MODAL_CLOSED':
      return {
        ...state,
        isOpen: false,
      };

    default:
      return state;
  }
};
