import React from 'react';

import {
  AuthContext,
  authReducer,
  initialAuthState,
} from './authContext/authContext';
import {
  LoginModalContext,
  initialLoginModalState,
  loginModalReducer,
} from './loginModalContext/loginModalContext';

const AppContext = ({ children }) => {
  const [authState, authDispatch] = React.useReducer(
    authReducer,
    initialAuthState
  );
  const [loginModalState, loginModalDispatch] = React.useReducer(
    loginModalReducer,
    initialLoginModalState
  );

  return (
    <AuthContext.Provider
      value={{
        authState,
        authDispatch,
      }}
    >
      <LoginModalContext.Provider
        value={{ loginModalState, loginModalDispatch }}
      >
        {children}
      </LoginModalContext.Provider>
    </AuthContext.Provider>
  );
};

export default AppContext;
